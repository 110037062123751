footer {
  flex-shrink: 0;
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d6efd;
  text-align: center;
  color: white;
}

footer p {
  padding-top: 1rem;
  width: 100%;
  text-align: center;
}
