.container-logo {
  background-image: url("../common/assets/background.png");
  background-repeat: repeat;
}
.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  width: 100%;
  max-width: 1200px;
}

.row-login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  width: 100%; /* Aumenta el ancho al 100% del contenedor */
  max-width: 600px; /* O el ancho máximo que prefieras */
}

.row-login h2 {
  text-align: center;
  margin-bottom: 20px;
}

.row-login label {
  display: block;
  margin-bottom: 10px; /* Mayor espacio entre etiquetas */
  font-weight: bold;
}

.form-control-login {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px; /* Mayor espacio entre campos de entrada */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-group-login {
  margin-bottom: 30px; /* Mayor espacio entre grupos de campos */
}

.btn-primary-login {
  background-color: #007bff;
  color: #fff;
  padding: 12px 20px; /* Botón ligeramente más grande */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary-login:hover {
  background-color: #0056b3;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input[type="password"]::-webkit-reveal,
input[type="password"]::-webkit-clear-button {
  display: none;
}

input[type="password"]::-moz-reveal,
input[type="password"]::-moz-clear-button {
  display: none;
}
