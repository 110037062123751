@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~react-toastify/dist/ReactToastify.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-90w {
  max-width: 90% !important;
}

.form-group.col-md-8 {
  margin-bottom: 20px;
}

.input-with-calendar {
  position: relative;
  display: inline-block;
}

.input-with-calendar input {
  padding-right: 30px;
  width: 200px;
}

.input-with-calendar i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #777;
  cursor: pointer;
}

.span-icon:hover {
  cursor: pointer; /* Cambia el cursor al estilo de un puntero */
  font-weight: bold;
}

.link-no-style {
  text-decoration: none;
  color: inherit;
}

.check-border {
  border: 0.5px solid #0d6efd;
}

.suggestion-box {
  position: relative;
  display: inline-block;
  margin-left: 7px;
  top: -3px;
}

.suggestion {
  background-color: #438efd;
  color: white;
  border-radius: 3px;
  margin-right: 7px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
}

.app-wrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}
