.office-container {
  margin-bottom: 5px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.office-title {
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  background-color: #333;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}

.schedule-table th,
.schedule-table td {
  padding: 10px;
  text-align: left;
}

.td-schedule-border {
  border-bottom: 3px solid #ddd;
}

.schedule-table th {
  background-color: #7a7a7ad2;
  color: white;
  font-weight: normal;
}

.schedule-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 4px;
}

.no-schedule-message {
  font-style: italic;
  color: #777;
  text-align: center;
  font-size: 16px;
  padding: 0px;
  margin-bottom: 0px;
}

.schedule-table th.date-column {
  width: 20%;
}

.schedule-table th.hours-column {
  width: 80%;
}
.spinner-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-right {
  display: flex;
  justify-content: right;
  align-items: right;
}
